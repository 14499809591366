/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'
import TitleDefault from 'components/elements/TitleDefault'
import GravityForm from 'components/shared/GravityForm'

// Images
import LocationIcon from 'img/location-icon.inline.svg'
import Phone from 'img/phone.inline.svg'
import Mail from 'img/mail.inline.svg'


const Hero = styled.div`

  overflow: hidden;

  @media (min-width: 992px) {
    min-height: 1000px;
  }

  @media (max-width: 991px) {
    min-height: 150px;
  }

`

const Content = styled(ParseContent)`

  & h1 {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size['100']};
    line-height: ${props => props.theme.font.size['90']};
    color: ${props => props.theme.color.text.light};
  }

  & p {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size['18']};
    line-height: 35px;

  }

  @media (max-width: 991px){
    & br {
      display: none;
    }

    & h1 {
      font-size: ${props => props.theme.font.size['80']};
      line-height: 80px;
    }

    & p {
      font-size: ${props => props.theme.font.size.m};
      line-height: 25px;
    }
  }
`


const ImageBackground = styled(Image)``

const Fade = styled.div`
  background-color: ${props => props.theme.color.face.main};
  z-index: -1;
`

const Background = styled.div`
  position: absolute;
  height: auto;
  max-height: 100vh;
  width: 100%;
  margin: 0;

  ${ImageBackground} {
    height: 100%;
    width: 100%;
    opacity: 0.45;
  }
`

const Right = styled.div`
  @media (min-width: 992px){
    padding-top: 7rem;
  }
`



const HeroOverOns = ({ fields }) => {

  return (
    <Hero className="position-relative">
      <Background className="row">
        <Fade className="col-lg-6 px-0 h-100">
          <ImageBackground src={fields.imageLeft} aboveFold />
        </Fade>
        <Right className="col-lg-6 px-0 d-none d-lg-block">
          <Image src={fields.imageRight} />    
        </Right>
      </Background>
      <div className="container py-5">
        <div className="row py-5">
          <div className="col-lg-6 pr-5 pt-5">
              <Content className="mb-4" content={fields.title} />
              <Content content={fields.description} />
          </div>
        </div>
        
      </div>
    </Hero>
  )
}

export default HeroOverOns