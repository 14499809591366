import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

// Blog
import BlogikReferenties from 'components/related/Referenties'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'


const Title = styled.div`
  font-family: ${props => props.theme.font.family.secondary};
  font-size: ${props => props.theme.font.size['100']};
  line-height: 100px;
  color: ${props => props.theme.color.text.secondary};

  @media screen and (max-width: 1199px) {
    font-size: ${props => props.theme.font.size.xxxl};
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`

const StyledReferenties = styled.div`

`

const Referenties = ({ className }) => {
  return (
    <StyledReferenties className={`${className ? `${className}` : ``} pb-5`}>
      <Blogik
        settings={{
          limit: 2,
          postType: 'referenties'
        }}
      >
        <BlogConsumer>
          {({ hasPosts, posts }) => {
            return (
              <div>
                {hasPosts && (
                  <>
                    <div className="row pb-md-4">
                      <div className="col-md-6">
                        <Title>Referenties</Title>
                      </div>
                    </div>
                    <BlogikReferenties posts={posts} />
                  </>
                )}

                {!hasPosts && (
                  <ParseContent content="Geen resultaten" />
                )}
              </div>
            )
          }}
        </BlogConsumer>
      </Blogik>
    </StyledReferenties>
  )
}

export default Referenties