import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'

// Components
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import { motion } from 'framer-motion'
import { useMedia } from 'react-use'

// Images
import UspIcon from 'img/usp-icon.inline.svg'
import CloseIcon from 'img/closeicon.inline.svg'

const Usp = styled(motion.div)`
  width: 100%;
  cursor: pointer;

  @media (min-width: 992px){
    max-width: 350px;
  }
`

const StyledImage = styled(Image)`
  width: 100%;
`

const Content = styled(ParseContent)`
  font-size: ${props => props.theme.font.size.m};
  font-weight: ${props => props.theme.font.weight.s};

  & p{
    text-transform: initial;
  }
`

const Stripe = styled.div`
  width: 62px;
  height: 1px;
  
  ${props => props.title === 'Kennis' && css`
    background-color: ${props.theme.color.face.main};
  `}
  
  ${props => props.title === 'Kwaliteit' && css`
    background-color: ${props.theme.color.face.contrast};
  `}

  ${props => props.title === 'Klantgericht' && css`
    background-color: ${props.theme.color.face.main};
  `}
`

const DienstTitle = styled(motion.div)`
  font-size: ${props => props.theme.font.size['30']};
  line-height: 35px;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px 35px;

  @media (min-width: 992px){
    max-width: 350px;
  }

  @media screen and (max-width: 991px) {
    padding: 5px 40px 5px 40px;
    font-size: ${props => props.theme.font.size.xm};
    line-height: 25px;
  }

  @media screen and (max-width: 768px) {
    padding: 15px 80px 15px 80px;
  }

  ${props => props.title === 'Kennis' && css`
    background-color: ${props.theme.color.face.contrast};
    color: ${props.theme.color.text.light};
  `}
  
  ${props => props.title === 'Kwaliteit' && css`
    background-color: ${props.theme.color.face.main};
    color: ${props.theme.color.text.light};
  `}

  ${props => props.title === 'Klantgericht' && css`
    background-color: ${props.theme.color.face.secondary};
    color: ${props.theme.color.text.light};
  `}
`

const Icon = styled(motion.div)`
  position: relative;
  top: -30px;
`

const Title = styled.span`
  position: relative;
  top: -15px;
`

const Usps = () => {
  const  { usps } = useStaticQuery(graphql`
    {
      usps: wordpressPage(wordpress_id: {eq: 11}) {
        acf {
          about {
            usps {
              image {
                localFile {
                  publicURL
                }
              }
              title
              description
            }
          }
        }
      }
    }
  `)

  const isMobile = useMedia('(max-width: 991px)')

  return (
    usps.acf.about.map(({ usps: { image, title, description } }, index) => (
      <div className="col-lg-4 col-md-6 mb-4 mb-lg-0" key={index}>
        <Usp
          initial="init"
          whileHover="hover"
          className="position-relative"
        >
          <StyledImage src={image} />
          <DienstTitle
            className="text-center"
            title={title}
            variants={{
              init: { height: isMobile ? '80px' : '100px', transition: { type: 'spring', damping: 15, delay: .3 } },
              hover: { height: '100%', transition: { type: 'spring', damping: 15 } }
            }}
          >
            <Icon 
              variants={{
                init: { display: 'block' },
                hover: { display: 'none' }
              }}
            >
              <UspIcon />
              </Icon>
            <motion.div 
              variants={{
                init: { height: '0px' },
                hover: { height: '50px' }
              }}
            />
              <Title>{title}</Title>
            <motion.div
              variants={{
                init: { display: 'none' },
                hover: { display: 'flex' }
              }}
              className="flex-wrap justify-content-center"
            >
            <Stripe className="my-4" title={title} />
            <motion.div
              className="w-100"
              variants={{
                init: { opacity: 0, pointerEvents: "none" },
                hover: { opacity: 1, pointerEvents: "all", transition: { type: 'spring', damping: 15, delay: .3 } }
              }}
            >  
              <Content content={description} />
            <motion.div 
              variants={{
                init: { display: 'none' },
                hover: { display: 'block' }
              }}
            >
              <CloseIcon />
            </motion.div>
            </motion.div>
          </motion.div>
        </DienstTitle>
      </Usp>
    </div>
    ))
  )
}

export default Usps