/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
// import ParseContent from 'components/shared/ParseContent'
import Referenties from 'components/elements/Referenties'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import HeroOverOns from 'components/elements/overons/HeroOverOns'
import Usps from 'components/elements/overons/Usps'
// import Team from 'components/elements/overons/Team'

const UspSection = styled.section`
  @media (min-width: 992px){
    margin-top: -20rem;
  }
`

// const Title = styled(ParseContent)`
//   & h2 {
//     font-family: ${props => props.theme.font.family.secondary};
//     font-size: ${props => props.theme.font.size['100']};
//     line-height: 100px;
//     color: ${props => props.theme.color.text.grey};
//   }

//   @media (max-width: 991px){
//     & h2 {
//       font-size: ${props => props.theme.font.size['80']};
//       line-height: 80px;
//     }
//   }
// `

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} article />
      
      <HeroOverOns fields={acf.banner} />

      <UspSection>
        <div className="container">
          <div className="row py-lg-5">
            <Usps />
          </div>
        </div>
      </UspSection>

      {/* <section>
        <div className="container pt-lg-5">
          <div className="row pt-5">
            <div className="col-12">
              <Title content={acf.team.title} />
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="pb-5">
        <Team />
      </section> */}

      <div className="container py-5">
        <Referenties />
      </div>

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      
      acf {
        banner {
          title
          imageLeft: image_left {
            localFile {
              publicURL
            }
          }
          imageRight: image_right {
            localFile {
              publicURL
            }
          }
          description
        }

        team {
          title
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
